import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDU0J2yY52JY7XyiK55n7u_RKgb226es0U',
  authDomain: 'mybook-2c73d.firebaseapp.com',
  projectId: 'mybook-2c73d',
  storageBucket: 'mybook-2c73d.appspot.com',
  messagingSenderId: '856074753303',
  appId: '1:856074753303:web:b8139fb7d49e68d2839352',
  measurementId: 'G-537K87JNR3',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
